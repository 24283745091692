<template>
  <b-card>
    <!-- form -->
    <validation-observer ref="editDepartment">
      <b-form
        @submit.prevent="handleSubmit"
        @keydown.enter="handleSubmit"
      >
        <b-row>
          <b-col cols="12">
            <h4 class="mb-2" >{{ $t('DetallesDepartamento') }}</h4>
          </b-col>
          <b-col>
            <validation-provider
              #default="{ errors }"
              :name="$t('Nombre')"
              rules="required"
            >
              <b-form-group
                :label="$t('Nombre')"
                label-for="department-name"
              >
                <b-form-input
                  v-model="department.name"
                  name="name"
                  :placeholder="$t('Nombre')"
                />
              </b-form-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>
          <b-col cols="12 text-right">
            <b-button
              type="submit"
              variant="primary"
              class="mt-2 mr-1"
            >
              {{ $t('Actualizar') }}
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import {
  BButton, BForm, BFormGroup, BFormInput, BRow, BCol, BCard,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, email } from '@validations'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      required,
      email,
    }
  },
  computed: {
    ...mapGetters({
      department: 'departments/getDepartment',
      currentClient: 'clients/getCurrentClient',
    }),
  },
  methods: {
    ...mapActions({
      edit: 'departments/edit',
      getDepartment: 'departments/getDepartment',
    }),
    handleSubmit() {
      this.$refs.editDepartment.validate().then(success => {
        if (success) {
          const { id } = this.department
          const formData = this.createFormData()
          if (formData) {
            this.edit({ id, department: formData })
          }
        }
      })
    },
    createFormData() {
      const data = new FormData()
      data.append('name', this.department.name)

      return data
    },
  },
  async created() {
    await this.getDepartment(this.$route.params.id)
  },
}
</script>

<style lang="scss">
</style>
